import { useContext } from 'react';
import imgAbout from '../../assets/Rolunk.jpg';
import { LanguageContext, Lang } from '../../common';
import aboutI18n from '../../content/aboutI18n';

export default function About() {
  const currentLanguage = useContext(LanguageContext).lang;
  const lang = new Lang(aboutI18n, currentLanguage);

  return (
    <section id="about" className="py-3">
      <div className="container-md">
        <h2 className="text-center py-3">
          {lang.get('ABOUT_TITLE')}
        </h2>
        <div className="row">
          <div className="col-md-8">
            {lang.get('ABOUT_TEXT')}
          </div>
          <div className="col-md-4">
            <img src={imgAbout} alt="Company headquarters" className="img-fluid img-thumbnail" />
          </div>
        </div>
      </div>
    </section>
  );
}
