/* eslint-disable camelcase */
import {
  FaGlobeAmericas,
  FaAnchor,
  FaPlane,
  FaTruck,
  FaCubes,
  FaExchangeAlt,
} from 'react-icons/fa';
import img_international_road_transportation from '../assets/services/Nemzetkozi-kozuti-szallitmanyozas.jpg';
import img_sea_freight from '../assets/services/Tengeri-szallitmanyozas.jpg';
import img_air_freight from '../assets/services/Legi-szallitmanyozas.jpg';
import img_domestic_freight from '../assets/services/Belfoldi-szallitmanyozas.jpg';
import img_warehouse_logistics from '../assets/services/Raktarlogisztika.jpg';
import img_customs from '../assets/services/Vamkezeles.jpg';

const servicesI18n = [
  {
    id: 'international_road_transportation',
    title: {
      hu: 'Nemzetközi közúti szállítmányozás',
      en: 'International Road Transport',
      de: 'Internationalen Straßenverkehr',
    },
    img: <FaGlobeAmericas />,
    imgBig: img_international_road_transportation,
    text: {
      hu: '1-től 24 tonnáig történő szállítás vonatkozásban állunk ügyfeleink rendelkezésére',
      en: 'We offer our customers transport solutions from 1 to 24 tonnes',
      de: 'Bezüglich der Lieferungen von 1 bis zu 24 Tonnen stehen wir für unsere Kunden zur Verfügung',
    },
    textLong: {
      hu: (
        <>
          Fő export import irányaink saját kocsikkal Németország, Svájc,
          Hollandia, Belgium, Dánia, Ausztria. Alvállalkozók bevonásával
          speditőr kollégáink leggyakrabban Olaszország, Anglia, Svédország,
          Lengyelország, Bulgária, Spanyolország, Románia, Franciaország
          viszonylatokat tudják megoldani. Komplett és részrakományok, valamint
          speciális (túlméretes, konténeres, hűtős..stb.) szállítások
          lebonyolításában munkatárasaink szakmai tapasztalata segíti a gyors,
          gazdaságos és színvonalas megoldásokat. Gépkocsijaink döntően
          különféle ponyvás felépítményű járművek, melyek kiválóan alkalmasak
          normál kereskedelmi áru szállítására.
        </>
      ),
      en: (
        <>
          Our main export directions are Germany, Switzerland, The Netherlands,
          Belgium, Denmark and Austria, using our own fleet. With the
          involvement of subcontractors, our forwarders most frequently cover
          shipments to and from Italy, England, Sweden, Poland, Bulgaria, Spain,
          Romania, and France. Our colleagues’ professional experience helps
          working out quick, economical and quality solutions for complete and
          partial loads and exceptional (oversize, container, cooler, etc.)
          shipments. Most of our trucks, trailers and semi-trailers are closed
          body tarpaulin configurations, perfectly suitable for the transport of
          various commodities.
        </>
      ),
      de: (
        <>
          Unsere vorrangigen Export- und Importrichtungen mit eigenen Wagen sind
          Deutschland, die Schweiz, die Niederlande, Belgien, Dänemark und
          Österreich. Unter Einbindung von Subunternehmern sind unsere
          Spediteurkollegen meistens in Italien, England, Schweden, Polen,
          Bulgarien, Spanien, Rumänien und Frankreich unterwegs. Bei der
          Abwicklung der Voll- und Teilfracht- bzw. speziellen Transport
          (Übergroß, Container, Kühlcontainer usw.) wirken unsere Mitarbeiter
          mit langjähriger Berufserfahrung und ermöglichen dadurch schnelle,
          günstige und qualitativ hochwertige Lösungen. Unsere Kraftfahrzeuge
          bestehen überwiegend aus Lkws mit Plane, die für die Beförderung
          normaler Waren hervorragend geeignet sind.
        </>
      ),
    },
  },
  {
    id: 'sea_freight',
    title: {
      hu: 'Tengeri szállítmányozás',
      en: 'Seafreight',
      de: 'Seefracht',
    },
    img: <FaAnchor />,
    imgBig: img_sea_freight,
    text: {
      hu: 'Egyéni szállítási megoldások háztól-házig',
      en: 'Individual transport solutions from door to door',
      de: 'Individuelle Transportlösung von Tür zu Tür',
    },
    textLong: {
      hu: (
        <>
          Cégünk megbízható pontos komplett tengeri ügyintézést vállal egészen
          az áru felvételétől a házhozszállításig, mely az ügyfél igényeinek
          megfelelően kiegészülhet vámkezeléssel (Európai kikötőkben vagy
          belföldön). Megbízóink igényeit figyelembe véve választjuk ki a
          fuvarfeladathoz legmegfelelőbb hajós társaságot, útvonalat, mind a
          teljes (FCL) mind a részrakományok és gyűjtőáru (LCL) szállítása
          esetén.
        </>
      ),
      en: (
        <>
          Our company offers full, reliable and precise maritime shipment
          administration including door-to-door delivery, which may be further
          supplemented with customs clearance upon customer’s request (at
          European ports or inland). Taking our customers’ needs into account,
          we select the most suitable shipping line and route, both for Full
          Container Load (FCL) and Less than Container Load (LCL) shipments.
        </>
      ),
      de: (
        <>
          Unsere Gesellschaft unternimmt die komplette und präzise
          Sachbearbeitung von Lieferungen im Bereich Seeverkehr von der Aufnahme
          der Güter bis hin zur Belieferung von Kunden, dies kann den
          Kundenwünschen entsprechend um die optionale Zollabwicklung ergänzt
          werden (in europäischen Häfen oder im Inland). Unter Berücksichtigung
          der Ansprüche unserer Kunden wird das für die jeweilige Lieferung am
          besten geeignete Schifffahrtunternehmen und Strecke sowohl bei Voll-
          (FCL) als auch bei Teilfrachten und Sammelgütern (LCL) ausgewählt.
        </>
      ),
    },
  },
  {
    id: 'air_freight',
    title: {
      hu: 'Légi szállítmányozás',
      en: 'Airfreight',
      de: 'Luftverkehr',
    },
    img: <FaPlane />,
    imgBig: img_air_freight,
    text: {
      hu: 'Testreszabott gyors, megbízható megoldások',
      en: 'Customized fast, reliable solutions',
      de: 'Zugeschnietten auf Kundenbedürfnisse schnelle, zuverlässige Lösungen',
    },
    textLong: {
      hu: (
        <>
          Szállításokat az egész világra kiterjedő hálózattal rendelkező
          megbízható partnereink segítségével rugalmasan oldjuk meg az időpontok
          pontos betartásával. Ezekre a kapcsolatokra alapozva vállaljuk a
          küldemények légi szállítmányozását a világ minden terminálján az ehhez
          tartozó logisztikai szolgáltatásokkal együtt.
        </>
      ),
      en: (
        <>
          Shipments are organised flexibly, with the help of reliable partners
          operating global networks, ensuring the punctuality of deliveries.
          Based on these connections, we undertake shipment by air to any port
          of the world, with the corresponding logistical services included.
        </>
      ),
      de: (
        <>
          Die Lieferungen werden mit Hilfe unserer zuverlässigen Partnern mit
          weltweitem Netzwerk flexibel und unter Einhaltung von Lieferfristen
          durchgeführt. Diese Kontakte dienen als Grundlage unserer
          Dienstleistungen im Luftverkehr im Bereich Beförderung von Sendungen
          in allen Häfen der Welt mit dem dazugehörigen Logistikdienstleistungen
          zusammen.
        </>
      ),
    },
  },
  {
    id: 'domestic_freight',
    title: {
      hu: 'Belföldi szállítmányozás',
      en: 'Domestic Transport',
      de: 'Inland Transport',
    },
    img: <FaTruck />,
    imgBig: img_domestic_freight,
    text: {
      hu: 'Országon belüli áruterítés és begyűjtés',
      en: 'National goods distribution and collection',
      de: 'Nationale Warenverteilung und Einsammlung',
    },
    textLong: {
      hu: (
        <>
          Megbízható alvállalkozók segítségével szervezzük belföldi
          áruterítéseinket illetve belföldi begyűjtéseinket. Szerződött
          partnereink részére disztribúciós szolgáltatást is végzünk.
          Gépkocsivezetők jó szakmai és helyismerettel rendelkeznek.
        </>
      ),
      en: (
        <>
          With the help of reliable subcontractors, we organise domestic goods
          distribution and collection. We also perform distribution services for
          our contracted partners. Our drivers are professionals with a thorough
          knowledge of the region.
        </>
      ),
      de: (
        <>
          Die Verteilung bzw. Einsammlung von Waren im Inland geschehen mit der
          Unterstützung von zuverlässigen Subunternehmern. Unseren
          Vertragspartner bieten wir auch Distributionsdienstleistungen an.
          Unsere Kraftfahrzeugfahrer verfügen über gute Fach- und
          Ortskenntnisse.
        </>
      ),
    },
  },
  {
    id: 'warehouse_logistics',
    title: {
      hu: 'Raktárlogisztika',
      en: 'Contract Logistic',
      de: 'Lagerlogistik',
    },
    img: <FaCubes />,
    imgBig: img_warehouse_logistics,
    text: {
      hu: 'Logisztikai megoldások értéknövelt tevékenységgel (Bérraktározás, Disztribúciós raktár, Puffer raktár kialakítása)',
      en: 'Logistics solutions with value-added activities (warehousing, distribution warehouses, storage buffer)',
      de: 'Logistiklösungen für wertschöpfende Aktivitäten (Warehousing, Auslieferungslager, Pufferspeicher entwickeln)',
    },
    textLong: {
      hu: (
        <>
          <p>
            Nemzetközi árufuvarozás és szállítmányozás mellett raktár logisztikai
            tevékenységet folyatunk biatorbágyi raktárunkban.
          </p>
          <p>
            <strong>Szolgáltatásink:</strong>
          </p>
          <ul>
            <li>
              raklapos és egyedi áruk tárolása tömbös vagy polcos megoldással
            </li>
            <li>betárolás, kitárolás</li>
            <li>komissiózás, árukiszedés, egységrakomány-képzés</li>
            <li>csomagolás, pántolás, fóliázás</li>
            <li>számítógépes készletnyilvántartás</li>
            <li>értéknövelt tevékenységek</li>
          </ul>
        </>
      ),
      en: (
        <>
          <p>
            We also undertake warehousing activities besides freight forwarding
            and shipments in our warehouse in Biatorbágy.
          </p>
          <p>
            <strong>Services:</strong>
          </p>
          <ul>
            <li>
              storage of palletised and individual goods in blocks or on
              shelves.
            </li>
            <li>placing in and removal from storage</li>
            <li>picking, removal of goods, formation of unit packages</li>
            <li>packaging, strapping, and shrink-wrapping</li>
            <li>computerised inventory management</li>
            <li>value added activities</li>
          </ul>
        </>
      ),
      de: (
        <>
          <p>
            Neben dem internationalen Güterverkehr und der Frachtbeförderung
            führen wir auch Lagerlogistikdienstleistungen in unserem Lagerhaus in
            Biatorbágy durch.
          </p>
          <p>
            <strong>Unsere Dienstleistungen:</strong>
          </p>
          <ul>
            <li>
              Lagerung von Palettenwaren und Einzelprodukten in Blöcken oder auf
              Palettenregalen.
            </li>
            <li>Einlagerung, Auslagerung</li>
            <li>
              Kommissionierung, Ausladung von Waren, Bildung von Ladeeinheiten
            </li>
            <li>Verpackung, Umreifung, Umhüllung</li>
            <li>EDV geführte Lagerbuchführung</li>
            <li>Mehrwertdienstleistungen</li>
          </ul>
        </>
      ),
    },
  },
  {
    id: 'customs',
    title: {
      hu: 'Vámkezelés',
      en: 'Customs Clearance',
      de: 'Verzollung',
    },
    img: <FaExchangeAlt />,
    imgBig: img_customs,
    text: {
      hu: 'Szabadforgalomba helyezés, áru továbbítás , Intrastat készítés és egyéni vámügynöki szolgáltatás',
      en: 'Free entry into service of cargo forwarding,individual customs services and Intrastat preparation .',
      de: 'Freier Eintritt in Spedition, Warenverteilung, Zolldienstleistungen und individuelle Vorbereitung Intrastat',
    },
    textLong: {
      hu: (
        <>
          Vámkezeléseinket szerződött partnereinkkel oldjuk meg, illetve
          maximálisan igazodunk ügyfeleink igényeihez. Partnereink naprakész
          ismeretekkel rendelkeznek a vámhivatalok elvárásairól, így aktívan
          közreműködnek a vámkezelési eljárás előkészítésében és
          lebonyolításában.
        </>
      ),
      en: (
        <>
          Customs clearance is performed by our contracted partners, in full
          conformity with our clients’ needs. Our Partners have up-to-date
          information on the requirements of customs authorities and can
          therefore actively contribute to the preparation and administration of
          the customs clearance procedure.
        </>
      ),
      de: (
        <>
          Die Zollabfertigung erfolgt durch unsere Vertragspartner, wir passen
          uns den Kundenansprüchen maximal an. Unsere Partner verfügen über
          tagesaktuelle Kenntnisse über die Anforderungen der Zollämter und
          wirken auf diese Weise aktiv bei der Vorbereitung und Abwicklung des
          Zollverfahrens mit.
        </>
      ),
    },
  },
];

const servicesTitleI18n = {
  title: {
    hu: 'Szolgáltatások',
    en: 'Services',
    de: 'Dienstleistungen',
  },
};

export default servicesI18n;
export { servicesTitleI18n };
