/* eslint-disable react/prop-types */
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LanguageContext } from '../common/settings';

export default function ChangeLanguage() {
  const params = useParams();
  const langContext = useContext(LanguageContext);
  langContext.setLang(params.language);

  return (
    <>
      lang:
      {' '}
      {params.language}
    </>
  );
}
