import * as i18n from './navbarI18nConst';

const navbarI18n = {
  [i18n.ABOUT]: {
    hu: 'Rólunk',
    en: 'About Us',
    de: 'Über uns',
  },
  [i18n.SERVICES]: {
    hu: 'Szolgáltatások',
    en: 'Services',
    de: 'Dienstleistung',
  },
  [i18n.TEAM]: {
    hu: 'Munkatársak',
    en: 'Employees',
    de: 'Mitarbeiter',
  },
  [i18n.CONTACT]: {
    hu: 'Kapcsolat',
    en: 'Contact',
    de: 'Kontakte',
  },
  [i18n.CAREER]: {
    hu: 'Karrier',
    en: '',
    de: '',
  },
  [i18n.E_SERVICE]: {
    hu: 'e-Szerviz',
    en: 'e-Service',
    de: 'e-Dienstleistungen',
  },
  [i18n.E_SERVICE_SUBCONTRACTORS]: {
    hu: '',
    en: '',
    de: '',
  },
  [i18n.E_SERVICE_SUBCONTRACTORS_MY_PAGE]: {
    hu: '',
    en: '',
    de: '',
  },
  [i18n.E_SERVICE_SUBCONTRACTORS_STATUS_MANAGEMENT]: {
    hu: '',
    en: '',
    de: '',
  },
  [i18n.E_SERVICE_SHIPPER]: {
    hu: '',
    en: '',
    de: '',
  },
  [i18n.E_SERVICE_SHIPPER_MY_PAGE]: {
    hu: '',
    en: '',
    de: '',
  },
  [i18n.E_SERVICE_SHIPPER_TRACKING]: {
    hu: '',
    en: '',
    de: '',
  },

};

export default navbarI18n;
