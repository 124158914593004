const carousel1 = {
  title: {
    hu: <>ÜGYFÉLKÖZPONTÚSÁG</>,
    en: <>CLIENT ORIENTATION</>,
    de: <>KUNDENORIENTIERTHEIT</>,
  },
  text: {
    hu: (
      <>
        Cél a Parnereinkkel együttműködve, hatékonyan kihasználni azokat a
        lehetőségeket, melyeket a külső és belső piaci környezet kínál. Fő
        feladatunk a költségegységre jutó hatékonyság maximalizálása, illetve a
        termékegységre jutó költség minimalizálása.
      </>
    ),
    en: (
      <>
        Our objective is to make efficient use of the opportunities offered by
        the internal and external markets in cooperation with our Partners. Our
        main task is to maximise our efficiency per unit of cost and to minimise
        the cost per unit of product.
      </>
    ),
    de: (
      <>
        Das Ziel ist die effiziente Ausnutzung der im inneren und äusseren
        Marktumfeld angebotenen Möglichkeiten im Zusammenarbeit mit unseren
        Partnern. Als unsere vorrangigen Aufgaben gelten die Maximierung der
        Effizienz pro Kosteneinheit bzw. die Minimierung der Kosten pro
        Produkteinheit.
      </>
    ),
  },
  button: {
    hu: 'AJÁNLATKÉRÉS',
    en: 'CLIENT ORIENTATION',
    de: 'KUNDENORIENTIERTHEIT',
  },
};

const carousel2 = {
  title: {
    hu: 'TAPASZTALAT',
    en: 'EXPERIENCE',
    de: 'ERFAHRUNG',
  },
  text: {
    hu: 'Sokéves szállítmányozási és logisztikai tapasztalat garantálja a vállalat sikerét. Fókuszban a szakmaiság, regionális szolgáltatókkal való együttműködés, logisztikai szolgáltatások komplexitása.',
    en: 'The many years of experience gained in the shipment and logistics sector guarantee the company’s success. Professionalism, cooperation with regional service providers and the complexity of the logistical services offered are in focus.',
    de: 'Unsere langjährige Erfahrung in den Bereichen Frachtbeförderung und Logistik steht als Garant für den Erfolg des Unternehmens. In unserem Fokus liegen Professionalität, Kooperation mit den Anbietern aus der Region und die Komplexität der Logistikdienstleistungen.',
  },
  button: {
    hu: 'MUNKATÁRSAINK',
    en: 'EXPERIENCE',
    de: 'ERFAHRUNG',
  },
};

const carousel3 = {
  title: {
    hu: 'MINŐSÉG',
    en: 'QUALITY',
    de: 'QUALITÄT',
  },
  text: {
    hu: 'Cégünk az üzleti szféra számára a logisztikai feladatok ellátásában versenyképes megoldást kínál. A vállalkozás alapvető tevékenysége, hogy biztosítsa Megbízói üzleti folyamatának zavartalan működését, termékeiket eljuttassa megfelelő helyre megfelelő időpontban, a szükségleteknek megfelelő mennyiségben és minőségben, megfelelő választékban, megfelelő költségen.',
    en: 'Our company offers competitive logistical solutions to the business sector. The basic activity of the business is to ensure the smooth and undisturbed course of the Customer’s business processes, delivering the Customer’s products to the right place at the right time in the desired quantities and quality, selection and at the appropriate cost.',
    de: 'Unser Unternehmen bietet wettbewerbsfähige Lösungen für die Geschäftssphäre auf dem Gebiet Verrichtung von Logistikaufgaben. Die grundlegende Tätigkeit des Unternehmens besteht in der Sicherstellung der reibungslosen Geschäftsaktivitäten der Auftraggeber, deren Waren rechtzeitig, in der den Bedürfnissen entsprechenden Menge und Qualität, mit entsprechendem Sortiment und zu angemessenen Kosten an dem entsprechenden Ort zu liefern.',
  },
  button: {
    hu: 'SZOLGÁLTATÁSAINK',
    en: 'QUALITY',
    de: 'QUALITÄT',
  },
};

export { carousel1, carousel2, carousel3 };
