import { useContext } from 'react';
import { LanguageContext, Lang } from '../../common';
import certificatesI18n from '../../content/certificatesI18n';
import iso9001hun from '../../assets/ISO9001-HUN.jpg';
import iso9001en from '../../assets/ISO9001-ENG.jpg';

export default function Certificates() {
  const currentLanguage = useContext(LanguageContext).lang;
  const lang = new Lang(certificatesI18n, currentLanguage);

  function ISO9001CertificateByLang() {
    let cert;

    switch (currentLanguage) {
      case 'hu':
        cert = (
          <img
            src={iso9001hun}
            className="img-fluid"
            alt="ISO 9001 Certificate"
          />
        );
        break;
      case 'en':
      case 'de':
        cert = (
          <img
            src={iso9001en}
            className="img-fluid"
            alt="ISO 9001 Certificate"
          />
        );
        break;
      default:
        cert = (
          <img
            src={iso9001en}
            className="img-fluid"
            alt="ISO 9001 Certificate"
          />
        );
    }

    return cert;
  }

  return (
    <section id="certificates" className="py-3">
      <div className="container-md">
        <h2 className="py-3 text-center">{lang.get('title')}</h2>
        <div className="row">
          <div className="col-md-8">
            <h2>{lang.get('subtitle')}</h2>
            <p>{lang.get('text')}</p>
          </div>
          <div className="col-md-4">
            <ISO9001CertificateByLang />
            {/* <img
              src={iso9001hun}
              className="img-fluid"
              alt="ISO 9001 Certificate"
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
}
