/* eslint-disable react/prop-types */
// import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { FaHome } from 'react-icons/fa';
import { useContext } from 'react';
import { HU, DE, GB } from 'country-flag-icons/react/3x2';
import {
  languageSet,
  LanguageContext,
  // languageSettings,
} from '../../common/settings';
import Lang from '../../common/Lang';
import navbarI18n from '../../content/navbarI18n';
import * as i18n from '../../content/navbarI18nConst';
import scrollWithOffset from '../../common/scrollWithOffset';
import logoNormal from '../../assets/logo.png';
import logoNormalSmall from '../../assets/logo-small.png';
import './navbar.scss';

export default function NavBar() {
  // source: https://github.com/rafgraph/react-router-hash-link/issues/25#issuecomment-536688104
  // const scrollWithOffset = (el) => {
  //   const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  //   const yOffset = -56;
  //   window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  // };

  const languageContext = useContext(LanguageContext);
  const currentLanguage = languageContext.lang;
  const otherLanguages = languageSet.filter((lang) => lang !== currentLanguage);

  const lang = new Lang(navbarI18n, currentLanguage);
  // const [newLanguage, setNewLanguage] = useState('');

  // function changeToNewLanguage(newLang) {
  //   languageContext.setLang(newLang);
  //   // setNewLanguage(newLang);
  // }

  // console.log(currentLanguage);
  // console.log(otherLanguages);

  function getFlag(flagKey) {
    let result;
    switch (flagKey) {
      case 'hu':
        result = <HU title="Magyar" className="" />;
        break;
      case 'en':
        result = <GB title="English" className="" />;
        break;
      case 'de':
        result = <DE title="Deutsch" className="" />;
        break;
      default:
        result = flagKey;
    }

    return result;
  }

  return (
    <nav id="navbar" className="navbar navbar-expand-lg navbar-light bg-light nav-smallcaps">
      <div className="container-fluid">
        <HashLink
          to="/#top"
          scroll={(el) => scrollWithOffset(el)}
        >
          <img src={logoNormal} alt="logo" className="d-none d-lg-block" />
          <img src={logoNormalSmall} alt="logo" className="d-lg-none" />
        </HashLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
          <div className="d-flex justify-content-end">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <HashLink
                  to="/#top"
                  className="nav-link"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  <FaHome />
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  to="/#about"
                  className="nav-link "
                  scroll={(el) => scrollWithOffset(el)}
                >
                  {lang.get(i18n.ABOUT)}
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  to="/#services"
                  className="nav-link"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  {lang.get(i18n.SERVICES)}
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  to="/#team"
                  className="nav-link"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  {lang.get(i18n.TEAM)}
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  to="/#contact"
                  className="nav-link"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  {lang.get(i18n.CONTACT)}
                </HashLink>
              </li>
              {
                currentLanguage === 'hu'
                  ? (
                    <li className="nav-item">
                      <HashLink
                        to="/#career"
                        className="nav-link"
                        scroll={(el) => scrollWithOffset(el)}
                      >
                        {lang.get(i18n.CAREER)}
                      </HashLink>
                    </li>
                  )
                  : null
              }
              {/*             DISABLED-NOT IN USE
              <li className="nav-item">
                <HashLink
                  to="/#"
                  className="nav-link"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  {lang.get(i18n.E_SERVICE)}
                </HashLink>
              </li> */}
              <li className="nav-item dropdown">
                {/* <button
                  className="nav-link btn dropdown-toggle remh"
                  type="button"
                  data-bs-toggle="dropdown"
                  href="/#"
                  aria-expanded="false"
                >
                  {languageSettings[currentLanguage].flag}
                </button> */}
                <button
                  id="language-selector-btn"
                  className="nav-link btn dropdown-toggle navbar-button"
                  type="button"
                  data-bs-toggle="dropdown"
                  href="/#"
                  aria-expanded="false"
                >
                  {getFlag(currentLanguage) }
                </button>
                <ul className="dropdown-menu dropdown-menu-end" id="language-select-dropdown-menu">
                  {otherLanguages.map((langKey) => (
                    <li key={langKey}>
                      <button
                        className="dropdown-item btn"
                        type="button"
                        onClick={() => languageContext.setLang(langKey)}
                      >
                        {getFlag(langKey)}
                      </button>
                      {/* <NavLink
                        className="dropdown-item"
                        to={`/change-language/${lang}`}
                      >
                        {lang}
                      </NavLink> */}
                    </li>
                  ))}
                </ul>
                {/* <ul className="dropdown-menu">
                  {otherLanguages.map((lang) => (
                    <li key={lang}>
                      <NavLink className="dropdown-item" to={`/change-language/${lang}`}>
                        {languageSettings[lang].flag}
                      </NavLink>
                    </li>
                  ))}
                </ul> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
