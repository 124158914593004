const teamTitleI18n = {
  title: {
    hu: 'Munkatársak',
    en: 'Team',
    de: 'Team',
  },
};

const teamI18n = [
  {
    name: {
      hu: 'Martényi Ágnes',
      en: 'Ágnes Martényi',
      de: 'Ágnes Martényi',
    },
    role: {
      hu: 'Ügyvezető igazgató',
      en: 'Executive Director',
      de: 'Geschäftsführer',
    },
    mobile: '',
    phone: '+36 23 530 609',
    email: 'martenyi@agltransport.hu',
    img: 'MartenyiAgnes.jpg',
  },
  {
    name: {
      hu: 'Halóka László',
      en: 'László Halóka',
      de: 'László Halóka',
    },
    role: {
      hu: 'Szállítmányozási igazgató',
      en: 'Freight Manager',
      de: 'Frachmanager',
    },
    mobile: '+36 20 961 8805',
    phone: '+36 23 530 605',
    email: 'haloka@agltransport.hu',
    img: 'HalokaLaszlo.jpg',
  },
  {
    name: {
      hu: 'Halóka Zsolt',
      en: 'Zsolt Halóka',
      de: 'Zsolt Halóka',
    },
    role: {
      hu: 'Fuvarszervező (export)',
      en: 'Freight Forwarder (export)',
      de: 'Frachtadministrator',
    },
    mobile: '+36 20 965 9275',
    phone: '+36 23 530 606',
    email: 'haloka.zs@agltransport.hu',
    img: 'HalokaZsolt.jpg',
  },
  {
    name: {
      hu: 'Csuti Tibor',
      en: 'Tibor Csuti',
      de: 'Tibor Csuti',
    },
    role: {
      hu: 'Szállítmányozó (Belföld)',
      en: 'Freight Forwarder (Domestic)',
      de: 'Frachtadministrator (Inland)',
    },
    mobile: '+36 30 884 5686',
    phone: '+36 23 530 611',
    email: 'csuti.tibor@agltransport.hu',
    img: 'Csuti-Tibor.jpg',
  },
  {
    name: {
      hu: 'Porta Gábor',
      en: 'Gábor Porta',
      de: 'Gábor Porta',
    },
    role: {
      hu: 'Szállítmányozó (Belföld)',
      en: 'Freight Forwarder (Domestic)',
      de: 'Frachtadministrator (Inland)',
    },
    mobile: '+36 30 092 7983',
    phone: '+36 23 530 611',
    email: 'porta.gabor@agltransport.hu',
    img: 'Porta_Gabor.jpg',
  },
  {
    name: {
      hu: 'Morcsányi Zoltán',
      en: 'Zoltán Morcsányi',
      de: 'Zoltán Morcsányi',
    },
    role: {
      hu: 'Fuvarszervező (import)',
      en: 'Freight Forwarder (import)',
      de: 'Frachtadministrator (import)',
    },
    mobile: '+36 30 9897120',
    phone: '+36 23 530 607',
    email: 'zoltan@agltransport.hu',
    img: 'MorcsanyiZoltan.jpg',
  },
  {
    name: {
      hu: 'Wagner Balázs',
      en: 'Balázs Wagner',
      de: 'Balázs Wagner',
    },
    role: {
      hu: 'Fuvarszervező (import)',
      en: 'Freight Forwarder (import)',
      de: 'Frachtadministrator (import)',
    },
    mobile: '+36 30 6952335',
    phone: '+36 23 530 608',
    email: 'wagner@agltransport.hu',
    img: 'WagnerBalazs.jpg',
  },
  {
    name: {
      hu: 'Nagy Zoltánné',
      en: 'Zoltánné Nagy',
      de: 'Zoltánné Nagy',
    },
    role: {
      hu: 'Adminisztrátor',
      en: 'Administrator',
      de: 'Forwarder',
    },
    mobile: '',
    phone: '+36 23 530 612',
    email: 'farkas@agltransport.hu',
    img: 'FarkasEszter.jpg',
  },
  {
    name: {
      hu: 'Pokorny Elektra Vasziliki',
      en: 'Elektra Vasziliki Pokorny',
      de: 'Elektra Vasziliki Pokorny',
    },
    role: {
      hu: 'Irodavezető',
      en: 'Office Manager',
      de: 'Büroleiter',
    },
    mobile: '',
    phone: '+36 23 530 600',
    email: 'elektra@agltransport.hu',
    img: 'WerteticsElektraVasziliki.jpg',
  },
  {
    name: {
      hu: 'Asperján Marcella',
      en: 'Marcella Asperján',
      de: 'Marcella Asperján',
    },
    role: {
      hu: 'Szállítmányozási vezető',
      en: 'Freight Leader',
      de: 'Versandleiter',
    },
    mobile: '+36 20 994 8803',
    phone: '+36 23 530 603',
    email: 'marcella@agltransport.hu',
    img: 'VargaMarcella.jpg',
  },
  {
    name: {
      hu: 'Nagy Bernadett',
      en: 'Bernadett Nagy',
      de: 'Bernadett Nagy',
    },
    role: {
      hu: 'Szállítmányozó',
      en: 'Freight Forwarder',
      de: 'Spediteur',
    },
    mobile: '+36 20 560 8725',
    phone: '+36 23 530 604',
    email: 'nagy@agltransport.hu',
    img: 'NagyBernadett.jpg',
  },
  {
    name: {
      hu: 'Csuhai-Csinos Zsolt',
      en: 'Zsolt Csuhai-Csinos',
      de: 'Zsolt Csuhai-Csinos',
    },
    role: {
      hu: 'Szállítmányozó',
      en: 'Freight Forwarder',
      de: 'Spediteur',
    },
    mobile: '+36 30 439 4168',
    phone: '+36 23 530 617',
    email: 'zsolt.csuhai@agltransport.hu',
    img: 'Csuhai-Csinos-Zsolt.jpg',
  },
  {
    name: {
      hu: 'Gorácz Bence',
      en: 'Bence Gorácz',
      de: 'Bence Gorácz',
    },
    role: {
      hu: 'Szállítmányozó',
      en: 'Freight Forwarder',
      de: 'Spediteur',
    },
    mobile: '+36 30 449 0440',
    phone: '+36 23 530 614',
    email: 'bence@agltransport.hu',
    img: 'Goracz-Bence.jpg',
  },
  {
    name: {
      hu: 'Erdélyi-Hámori Bernadett',
      en: 'Bernadett Erdélyi-Hámori',
      de: 'Bernadett Erdélyi-Hámori',
    },
    role: {
      hu: 'Szállítmányozó',
      en: 'Freight Forwarder',
      de: 'Spediteur',
    },
    mobile: '+36 30 565 6760',
    phone: '+36 23 530 601',
    email: 'hamoribetti@agltransport.hu',
    img: 'Erdélyi-Hámori Bernadett-20240619_143836.jpg',
  },
  {
    name: {
      hu: 'Réti Tamás',
      en: 'Tamás Réti',
      de: 'Tamás Réti',
    },
    role: {
      hu: 'Szállítmányozási Asszisztens',
      en: 'Freight Forwarder Assistant',
      de: 'Spediteur Assistent',
    },
    mobile: '',
    phone: '+36 23 530 618',
    email: 'tamas@agltransport.hu',
    img: 'Reti-Tamas.jpg',
  },
  {
    name: {
      hu: 'Juhász Mónika',
      en: 'Mónika Juhász',
      de: 'Mónika Juhász',
    },
    role: {
      hu: 'Adminisztrátor',
      en: 'Administrator',
      de: 'Forwarder',
    },
    mobile: '',
    phone: '+36 23 530 610',
    email: 'juhasz.monika@agltransport.hu',
    img: 'Juhasz-Monika.jpg',
  },
  {
    name: {
      hu: 'Göndör Anikó',
      en: 'Anikó Göndör',
      de: 'Anikó Göndör',
    },
    role: {
      hu: 'Üzletkötő',
      en: 'Sales executive',
      de: 'Verkaufer',
    },
    mobile: '+36 20 318 0001',
    email: 'gondor@agltransport.hu',
    img: 'GondorAniko.jpg',
  },
  {
    name: {
      hu: 'Halóka Lilla',
      en: 'Lilla Halóka',
      de: 'Lilla Halóka',
    },
    role: {
      hu: 'Könyvelő',
      en: 'Accountant',
      de: 'Buchhalter',
    },
    mobile: '',
    phone: '+36 23 530 609',
    email: 'lilla@agltransport.hu',
    img: 'HalokaLilla.jpg',
  },
  {
    name: {
      hu: 'Varga Szűcs Mónika',
      en: 'Mónika Varga Szűcs',
      de: 'Mónika Varga Szűcs',
    },
    role: {
      hu: 'Pénzügyi előadó',
      en: 'Revenue Officer',
      de: 'Finanzforsdtand',
    },
    mobile: '',
    phone: '+36 23 530 602',
    email: 'monika@agltransport.hu',
    img: 'VargaSzucsMonika.jpg',
  },
];

export default teamI18n;
export { teamTitleI18n };
