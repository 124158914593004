import { useContext } from 'react';
import {
  FaRegEnvelope,
  FaGlobeAmericas,
  FaPhoneAlt,
  FaRegCopyright,
} from 'react-icons/fa';
import { Lang, LanguageContext } from '../../common';
import basicI18n from '../../content/basicI18n';
import certificateSmall from '../../assets/iso9001-hu.jpg';
// import aaaRating from '../../assets/AAA-hu.png';
import DandBCertificateHU from '../../assets/DandBCertificateHU.jpg';
import DandBCertificateEN from '../../assets/DandBCertificateEN.jpg';
import DandBCertificateDE from '../../assets/DandBCertificateDE.jpg';

export default function Footer() {
  const currentLanguage = useContext(LanguageContext).lang;
  const lang = new Lang(basicI18n, currentLanguage);
  const currentYear = new Date().getFullYear();

  function DandBCertificate() {
    let cert;

    switch (currentLanguage) {
      case 'hu':
        cert = (
          <img
            src={DandBCertificateHU}
            alt="ISO 9001 Certificate"
            height="90"
          />
        );
        break;
      case 'en':
        cert = (
          <img
            src={DandBCertificateEN}
            alt="ISO 9001 Certificate"
            height="90"
          />
        );
        break;
      case 'de':
        cert = (
          <img
            src={DandBCertificateDE}
            alt="ISO 9001 Certificate"
            height="90"
          />
        );
        break;
      default:
        cert = (
          <img
            src={DandBCertificateEN}
            alt="ISO 9001 Certificate"
            height="90"
          />
        );
    }

    return cert;
  }

  return (
    // <section id="footer" className="position-fixed bottom-0 bg-dark text-white w-100">
    <section id="footer" className="bg-dark text-white w-100">
      {/* <div className="row justify-content-between">
        <div className="col">
          <div className="row">
            <div className="col">A.G.L. Transport Kft</div>
            <div className="col">
              <div>
                H - 2051 Biatorbágy, Rozália Park 11.
              </div>
              <div>
                +36 (23) 530-600  +36 (23) 530-619
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <a href="mailto:agltrans@agltransport.hu">
            <FaRegEnvelope className="me-2" />

          </a>
          <a href="https://www.agltransport.hu">
            <FaGlobeAmericas className="me-2" />

          </a>
        </div>
      </div> */}
      <div className="d-flex justify-content-between py-3">
        <div>
          <div className="d-flex">
            <div className="px-3">{lang.get('companyName')}</div>
            <div className="px-3">
              <div>H - 2051 Biatorbágy, Rozália Park 11.</div>
              <div className="d-flex flex-row">
                <div className="pe-2">
                  <FaPhoneAlt
                    title={lang.get('landline')}
                    className="text-secondary"
                  />
                  {' '}
                  +36 (23) 530-600
                </div>
                {/* <div className="pe-2">
                  <FaFax title={lang.get('fax')} className="text-secondary" />
                  {' '}
                  +36 (23) 530-619
                </div> */}
              </div>
            </div>
            <div className="d-flex flex-row">
              <a
                // href="mailto:agltrans@agltransport.hu"
                href="mailto:agltrans@agltransport.hu"
                className="p-2 footer-link"
              >
                <FaRegEnvelope
                  className="me-2"
                  size="2rem"
                  title={lang.get('writeToUs')}
                />
              </a>
              <a href="https://www.agltransport.hu" className="p-2 footer-link">
                <FaGlobeAmericas
                  className="me-2"
                  size="2rem"
                  title={lang.get('website')}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="d-flex px-3">
          <div>
            {/* <img src={aaaRating} alt="rating" title="AAA rating" height="90" /> */}
            <DandBCertificate />
          </div>
          <div>
            <img
              src={certificateSmall}
              alt="certificate"
              title="Certificate"
              height="90"
            />
          </div>
        </div>
      </div>
      <div className="text-center py-2">
        <FaRegCopyright />
        {' '}
        {currentYear}
        {' '}
        A.G.L. Transport Kft.
      </div>
    </section>
  );
}
