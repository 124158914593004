const certificatesI18n = {
  title: {
    hu: 'Minősítések',
    en: 'Certification',
    de: 'Qualifizierung',
  },
  subtitle: {
    hu: 'ISO 9001',
    en: 'ISO 9001',
    de: 'ISO 9001',
  },
  text: {
    hu: (
      <>
        Cégünk 2015 decemberében bevezette az MSZ EN ISO 9001 szabvány szerinti
        minőségirányítási rendszert. A MARTON Szakértő Iroda Kft.
        közreműködésével felülvizsgáltuk, pontosítottuk és az előírásokkal
        összhangban dokumentáltuk a cég folyamatait. Az így elkészített
        minőségirányítási kézikönyv garancia ügyfeleink tökéletes
        kiszolgálására.
      </>
    ),
    en: (
      <>
        In December 2015 our company introduced the quality management system
        according to standard MSZ EN 9001. With the cooperation of MARTON
        Szakértő Iroda Kft. we inspected, specified and documented the processes
        of the company in agreement with the regulations. The quality management
        reference book based on this is a guarantee for the perfect service of
        our clients.
      </>
    ),
    de: (
      <>
        In Dezember 2015 hat unsere Firma die Qualitätsmanagement System
        entsprechend dem MSZ EN 9001 eingeführt. Mit der Mitwirkung der MARTON
        Szakértő Iroda Kft. haben wir die Prozesse der Firma vorschriftsgemäß
        übergeprüft, präzisiert und dokumentiert. Das so angefertigte
        Qualitätsmanagement Handbuch ist eine Garantie für die perfekte
        Bedienung der Kunden.
      </>
    ),
  },
};

export default certificatesI18n;
