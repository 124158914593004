const contactI18n = {
  title: {
    hu: 'Kapcsolat',
    en: 'Contact',
    de: 'Kontakt',
  },
  address: {
    hu: 'Cím',
    en: 'Address',
    de: 'Adresse',
  },
  contact: {
    hu: 'Elérhetőségek',
    en: 'Contact',
    de: 'Kontakt',
  },
  contactUsTitle: {
    hu: 'Kapcsolatfelvétel',
    en: 'Contact us',
    de: 'Kontaktiere uns',
  },
  contactUsText: {
    hu: 'Vegye fel velünk a kapcsolatot, kollégánk hamarosan felkeresi Önt!',
    en: 'Get in touch, our colleague will contact you soon!',
    de: 'Kontaktieren Sie uns, unser Kollege wird sich bald bei Ihnen melden!',
  },
  formName: {
    hu: 'Az Ön neve',
    en: 'Your name',
    de: 'Ihr Name',
  },
  formEmail: {
    hu: 'E-mail címe',
    en: 'Email address',
    de: 'Seine E-Mail-Adresse',
  },
  formPhone: {
    hu: 'Telefonszáma',
    en: 'Your phone number',
    de: 'Ihre Telefonnummer',
  },
  formMessage: {
    hu: 'Üzenete kollégánk számára',
    en: 'Your message for our colleague',
    de: 'Ihre Nachricht an unseren Kollegen',
  },
  formCheckPrivacyStatement: {
    hu: 'Hozzájárulok ahhoz, hogy adataimat az AGL Transport Kft az adatvédelmi tájékoztató szerint feldolgozza.',
    en: 'I agree to my data being stored and handled by AGL Transport Kft. according to the Privacy Statement.',
    de: 'Ich stimme der Verarbeitung meiner Daten durch AGL Transport Kft. gemäß den Datenschutzinformationen zu.',
  },
  requestForQuote: {
    hu: 'Ajánlatkérés',
    en: 'Request for quote',
    de: 'Angebotsanfrage',
  },
};

export default contactI18n;
