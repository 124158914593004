/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import {
  FaMobileAlt, FaPhoneAlt, FaRegEnvelope, FaFax,
} from 'react-icons/fa';
import { useContext } from 'react';
import teamI18n, { teamTitleI18n } from '../../content/teamI18n';
import basicI18n from '../../content/basicI18n';
import { LanguageContext, Lang } from '../../common';

function MemberCard({ member, currentLanguage }) {
  const lang = new Lang(member, currentLanguage);
  const memberName = lang.get('name');
  const langBasic = new Lang(basicI18n, currentLanguage);

  return (
    <div className="col">
      <div className="card background-grey border-0">
        <div className="row">
          <div className="col-4">
            <img
              src={`${process.env.PUBLIC_URL}/assets/team/${member.img}`}
              alt={memberName}
              title={memberName}
              className="img-fluid img-thumbnail"
            />
          </div>
          <div className="col-8">
            <div className="card-body">
              <h3 className="card-title">{memberName}</h3>
              <h5 className="card-subtitle pb-2 text-muted smallcaps">{lang.get('role')}</h5>
              {member.mobile && (
              <div className="card-text">
                <FaMobileAlt className="me-2" title={langBasic.get('mobile')} />
                {member.mobile}
              </div>
              )}
              {member.phone && (
              <div className="card-text">
                <FaPhoneAlt className="me-2" title={langBasic.get('landline')} />
                {member.phone}
              </div>
              )}
              {member.fax && (
              <div className="card-text">
                <FaFax className="me-2" title={langBasic.get('fax')} />
                {member.fax}
              </div>
              )}
              {member.email && (
              <div className="card-text">
                <FaRegEnvelope className="me-2" title={langBasic.get('email')} />
                <a href={`mailto:${member.email}`} className="link-decoration">
                  {member.email}
                </a>
              </div>
              )}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Team() {
  const currentLanguage = useContext(LanguageContext).lang;
  let idx = 0;
  const teamArray = teamI18n.map((member) => {
    const newMember = { idx, ...member };
    idx++;
    return newMember;
  });

  const langTitle = new Lang(teamTitleI18n, currentLanguage);

  return (
    <section id="team" className="background-grey py-3">
      <div className="container-md">
        <h2 className="py-3 text-center">
          {langTitle.get('title')}
        </h2>
        <div className="row row-cols-1 row-cols-md-2 g-4">
          {teamArray.map((member) => (
            <MemberCard member={member} currentLanguage={currentLanguage} key={member.idx} />
          ))}
        </div>
      </div>
    </section>
  );
}
