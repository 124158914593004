/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import { HashLink } from 'react-router-hash-link';
import { useContext } from 'react';
import servicesI18n, { servicesTitleI18n } from '../../content/servicesI18n';
import scrollWithOffset from '../../common/scrollWithOffset';
import { LanguageContext, Lang } from '../../common';

function ServiceCardShort({ card, currentLanguage }) {
  const lang = new Lang(card, currentLanguage);

  return (
    <div className="col">
      <div className="card border-0 background-grey p-3">
        <div className="card-body">
          <div className="w-100 text-center display-3 p-4">
            <HashLink to={`#${card.id}`} scroll={(el) => scrollWithOffset(el)}>
              {card.img}
            </HashLink>
          </div>
          <h5 className="card-title text-center my-3">{lang.get('title')}</h5>
          <p className="card-text">{lang.get('text')}</p>
        </div>
      </div>
    </div>
  );
}

// function LongRight({ card }) {
//   return (

//     <div className="card">
//       <div className="row g-0">
//         <div className="col-md-3">
//           <img src={card.imgBig} alt={card.title} />
//         </div>
//         <div className="col-md-9">
//           <div className="card-body">
//             <h5 className="card-title">{card.title}</h5>
//             <div className="card-text">{card.textLong}</div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// function LongLeft({ card }) {
//   return (

//     <div className="card">
//       <div className="row g-0">
//         <div className="col-md-9">
//           <div className="card-body">
//             <h5 className="card-title">{card.title}</h5>
//             <div className="card-text">{card.textLong}</div>
//           </div>
//         </div>
//         <div className="col-md-3">
//           <img src={card.imgBig} alt={card.title} />
//         </div>
//       </div>
//     </div>
//   );
// }

function ServiceCardLong({ card, currentLanguage }) {
  const lang = new Lang(card, currentLanguage);

  const image = (
    <div className="col-md-3 py-3" key="cardimg">
      <img
        src={card.imgBig}
        alt={lang.get('title')}
        className="img-fluid img-thumbnail"
      />
    </div>
  );

  const text = (
    <div className="col-md-9" key="cardtext">
      <div className="card-body">
        <h2 className="card-title px-3 pb-2 border-bottom border-2 border-secondary">
          {lang.get('title')}
        </h2>
        <div className="card-text px-3">{lang.get('textLong')}</div>
      </div>
    </div>
  );

  // let a;
  // if (card.idx % 2 === 1) { a = [image, text]; } else { a = [text, image]; }

  return (
    <div className="card border-0" id={card.id}>
      {/* TODO: make more responsive */}
      <div className="row g-0">
        {card.idx % 2 === 1 ? [image, text] : [text, image]}
      </div>
    </div>
  );
}

export default function Services() {
  const currentLanguage = useContext(LanguageContext).lang;
  const langTitle = new Lang(servicesTitleI18n, currentLanguage);

  let idx = 0;
  // TODO: rewrite for map
  servicesI18n.forEach((card) => {
    // eslint-disable-next-line no-param-reassign
    card.idx = idx;
    idx++;
  });

  return (
    <>
      <section id="services" className="background-grey w-100 py-3">
        <div className="container-md">
          <h2 className="py-4 text-center">
            {langTitle.get('title')}
          </h2>
          <div className="row row-cols-1 row-cols-md-3 g-4">
            {servicesI18n.map((card) => (
              <ServiceCardShort card={card} currentLanguage={currentLanguage} key={card.id} />
            ))}
          </div>
        </div>
      </section>
      <section className="py-3">
        <div className="container-md">
          {servicesI18n.map((card) => (
            <ServiceCardLong card={card} currentLanguage={currentLanguage} key={card.id} />
          ))}
          {/* {servicesData.map((card) => {
          let out;
          if (card.idx % 2 === 1) {
            out = <LongRight card={card} key={card.id} />;
          } else {
            out = <LongLeft card={card} key={card.id} />;
          }
          return out;
        })}
      ; */}
        </div>
      </section>
    </>
  );
}
