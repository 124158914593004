import { useContext } from 'react';
import { LanguageContext } from '../../common/settings';

export default function Career() {
  const currentLanguage = useContext(LanguageContext).lang;

  if (currentLanguage !== 'hu') {
    return null;
  }

  return (
    <section id="career" className="py-3">
      <div className="container-md">
        <h2 className="py-3 text-center">Aktuálisan hirdetett pozíció(k):</h2>
        {/* <article className="py-3">
          <h3>NEMZETKÖZI GÉPKOCSIVEZETŐ</h3>
          <p>
            AGL Transport Kft biatorbágyi telephelyére keres nyerges vontatóra
            gépkocsivezetőt. Jellemzően német,holland svájci fuvarokat, végzünk
            részrakományokkal. Gyakori a szombati indulás.45ös pinenő otthon.
          </p>
          <p>
            Jelentkezni fényképes önéletrajzzal: haloka@agltransport.hu e-mail
            címen lehet.
          </p>
        </article> */}
        <article className="py-3">
          <img
            src={`${process.env.PUBLIC_URL}/assets/AGL_career1.jpg`}
            alt="career-ad"
            className="img-fluid"
          />
        </article>
        <article className="py-3">
          <h3>SZÁLLÍTMÁNYOZÓ</h3>
          <p>
            AGL Transport Kft biatorbágyi telephelyére szállítmányozót keresünk
          </p>
          <p>Feladatok:</p>
          <ul>
            <li>cégünk nemzetközi közúti szállítmányainak koordinálása,</li>
            <li>küldemények diszponálása és adminisztrációja,</li>
            <li>folyamatos együttműködés a fuvarozókkal és megrendelőkkel,</li>
            <li>
              alvállalkozók keresése,a szállítmányok követése és ezek
              dokumentálása.
            </li>
          </ul>
          <p>Elvárások:</p>
          <ul>
            <li>szállítmányozási tapasztalat 1-2 év</li>
            <li>
              egy idegen nyelv társalgás szintű használata (német előnyben)
            </li>
            <li>nagyfokú precizitás</li>
            <li>megbízhatóság és terhelhetőség</li>
            <li>csapatmunka</li>
            <li>kiváló problémamegoldó képesség</li>
          </ul>
          <p>Amit kínálunk:</p>
          <ul>
            <li>stabil munkahely 20 éves cég háttér</li>
            <li>családias és fiatalos munkahelyi légkör</li>
            <li>kimagasló bérezés.</li>
          </ul>
          <p>
            Jelentkezni lehet fényképes önéletrajzzal:
            {' '}
            <a href="mailto:agltrans@agltransport.hu" className="">
              haloka@agltransport.hu e-mail
            </a>
            {' '}
            címre illetve a +36 23 530 605 telefonszámon.
          </p>
        </article>
        {/* <article className="py-3">
          <h3>
            BELFÖLDI GÉPKOCSIVEZETŐ
          </h3>
          <p>
            AGL Transport Kft Biatorbágyi telephelyére keres környékbeli
            gépkocsivezetőt ponyvás 12tás Iveco Eurocargora.
          </p>
          <p>
            Feladata a társaságunk belfölddel kapcsolatos fuvarfeladatinak
            ellátása.
          </p>
          <p>Elvárás 1-2 éves tapasztalat,GKI,sofőrkártya PÁvii Eü .</p>
          <p>Jelentkezni önéletrajzzal a csuti.tibor@agltransport.hu.</p>
        </article> */}
      </div>
    </section>
  );
}
