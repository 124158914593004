import React from 'react';

const defaultLanguage = 'hu';

export default class Lang {
  constructor(translationData, language) {
    this.translationData = translationData;
    this.language = language;
  }

  get(key) {
    let out;
    // Check whether the key exists in the translation object, else return the key
    if (this.translationData[key]) {
      // console.log(`Found key ${key} target: ${this.language}`);

      // Check whether key has the desired language
      if (this.translationData[key][this.language]) {
        // console.log(`Found key/set lang ${key}/${this.language}`);

        out = this.translationData[key][this.language];
        // else get the default language
      } else if (this.translationData[key][defaultLanguage]) {
        // console.log(`Found key/default lang ${key}/${defaultLanguage}`);

        out = this.translationData[key][defaultLanguage];
        // if neither is present, check whether the key itself contains a valid element
        // if not, return just the key
      } else if (React.isValidElement(this.translationData[key])) {
        out = this.translationData[key];
      }
    } else {
      out = key;
    }
    return out;
  }
}
