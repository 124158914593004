export default function GoogleMap() {
  const mapMode = 'place';
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_APIKEY;
  console.log(apiKey);
  const q = 'A.G.L.+Transport+Kft.';

  return (
    <iframe
      title="GoogleMaps Embedded"
      width="500"
      height="300"
      // frameBorder="0"
      // style="border:0"
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/${mapMode}?key=${apiKey}&q=${q}`}
      allowFullScreen
    />
  );
}
