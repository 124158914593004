/* eslint-disable react/jsx-no-bind */
// import { useRef } from 'react';
import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ChangeLanguage } from './pages';
import { Footer, NavBar } from './pages/components';
import MainPage from './pages/MainPage';
import { LanguageContext } from './common/settings';

function App() {
  // const aboutRef = useRef();
  // const servicesRef = useRef();

  const [lang, setLang] = useState('hu');

  // function selectLanguage(language) {
  //   setLang(language);
  // }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <LanguageContext.Provider value={{ lang, setLang }}>
      <div className="App">
        <header className="App-header sticky-top">
          <NavBar />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route
              path="/change-language/:language"
              element={<ChangeLanguage />}
            />
            {/* <Route path="/change-language/:language"
            element={<ChangeLanguage selectLanguage={selectLanguage} />} /> */}

            {/* <Route path="/change-language/" element={<MainPage />}>
              <Route path=":language"
              element={<ChangeLanguage selectLanguage={selectLanguage} />} />
            </Route> */}
            {/* <Route path="/services" element={<MainPage navTo="services" />} /> */}
            {/* <Route path="about" element={<About />} /> */}
          </Routes>

        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </LanguageContext.Provider>
  );
}

export default App;
