// source: https://github.com/rafgraph/react-router-hash-link/issues/25#issuecomment-536688104

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  // const yOffset = -56;
  const yOffset = -66;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

export default scrollWithOffset;
