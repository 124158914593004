/* eslint-disable no-unused-vars */
const operatingYears = new Date().getFullYear() - 1996;
function AboutHU() {
  return (
    <>
      <p>
        Az
        {' '}
        <strong>AGL Transport Kft.</strong>
        {' '}
        1996-ban alakult, 100 %-ban
        magyar tulajdonú, minőségi szolgáltatást nyújtó vállalkozás.
        {' '}
      </p>
      <p>
        Cégünk több, mint
        {' '}
        {operatingYears}
        {' '}
        éves szakmai múltjából fakadóan széleskörű
        Megbízói kört szolgál ki, így jelentős szerepet tölt be a nemzetközi
        áruszállítás területén. Fő profilja a nemzetközi illetve belföldi
        fuvarozás, szállítmányozás, melyet a megnövekedett igényeknek
        megfelelően további szolgáltatásokkal bővítünk. Tengeri, légi
        szállítások szervezését vállaljuk illetve teljes körű, komplex
        logisztikai szolgáltatásokat szeretnénk nyújtani eddigi és leendő
        partnereink részére.
      </p>
      <p>
        Jelenlegi ügyfélkörünk a gazdasági élet különböző területein
        tevékenykednek. Kialakult hosszú távú partnerkapcsolatainkat
        megbízhatóságunknak, pontosságunknak köszönhetjük.
      </p>
    </>
  );
}

function AboutEN() {
  return (
    <>
      <p>
        <strong>AGL Transport Kft.</strong>
        {' '}
        was established in 1996 and is a
        100% Hungarian owned company specialised in quality logistics
        services.
      </p>
      <p>
        Thanks to our
        {' '}
        {operatingYears}
        {' '}
        active years in the sector behind us, we now serve a
        broad circle of Customers, playing a significant role in international
        freight transport. Our main profile is domestic and international
        transportation and shipment, supplemented by further services to meet
        increasingly higher customer expectations. We organise maritime and
        air deliveries and full logistical service packages to our existing
        and new partners.
      </p>
      <p>
        Our present customer base is made up of companies operating in various
        business sectors; the long term business cooperation we have
        established with them is testimony to our reliability and punctuality.
      </p>
    </>
  );
}

function AboutDE() {
  return (
    <>
      <p>
        Die
        {' '}
        <strong>AGL Transport Kft.</strong>
        {' '}
        wurde 1996 gegründet, befindet
        sich zu 100 Prozent in ungarischem Eigentum und bietet qualitativ
        hochwertige Dienstleistungen an.
      </p>
      <p>
        Vor dem Hintergrund der mehr als
        {' '}
        {operatingYears}
        -jährigen beruflichen Vergangenheit
        arbeitet unser Unternehmen mit einer Vielzahl von Auftraggebern
        zusammen und besitzt eine signifikante Rolle im Bereich internationale
        Warenbeförderung. Das Hauptprofil des Unternehmens sind
        Frachtbeförderung im ungarischen Binnenverkehr bzw. Frachtbeförderung
        international, dieser Bereich wird den Bedürfnissen entsprechend, um
        weitere Dienstleistungen erweitert. Wir unternehmen die komplette
        Durchführung von Frachtbeförderung im See- und Luftverkehr bzw.
        streben wir an, unseren aktuellen und potenziellen Kunden komplexe
        Logistikdienstleistungen anzubieten.
      </p>
      <p>
        Unsere aktuelle Kunden sind auf unterschiedlichen Gebieten des
        Wirtschaftslebens tätig, unsere gewachsene langjährige
        Partnerbeziehungen sind der Zuverlässigkeit und Präzision dieser
        Partner zu verdanken.
      </p>
    </>
  );
}

const aboutI18n = {
  ABOUT_TITLE: {
    hu: 'Rólunk',
    en: 'About Us',
    de: 'Über uns',
  },
  ABOUT_TEXT: {
    hu: <AboutHU />,
    en: <AboutEN />,
    de: <AboutDE />,
  },
};

export default aboutI18n;
