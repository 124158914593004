const basicI18n = {
  companyName: {
    hu: 'A.G.L. Transport Kft.',
    en: 'A.G.L. Transport Ltd.',
    de: 'A.G.L. Transport GmbH',
  },
  email: {
    hu: 'e-mail cím',
    en: 'email address',
    de: 'E-Mail-Addresse',
  },
  mobile: {
    hu: '',
    en: 'mobile phone number',
    de: 'Mobiltelefonnummer',
  },
  landline: {
    hu: 'telefonszám',
    en: 'phone number',
    de: 'Telefonnummer',
  },
  fax: {
    hu: 'fax',
    en: 'fax',
    de: 'Fax',
  },
  writeToUs: {
    hu: 'Írjon nekünk!',
    en: 'Write to us!',
    de: 'Schreiben Sie uns!',
  },
  website: {
    hu: 'weboldal',
    en: 'website',
    de: 'Webseite',
  },

};

export default basicI18n;
