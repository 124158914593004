import {
  About, Banner, Career, Certificates, Contact, Services, Team,
} from './components';

export default function MainPage() {
  return (
    <div className="container-fluid">
      <Banner />
      <About />
      <Services />
      <Certificates />
      <Team />
      <Career />
      <Contact />
      <div className="padding-bottom" />
    </div>
  );
}
