import { useContext } from 'react';
import {
  FaPhoneAlt,
  FaRegEnvelope,
  // FaFax,
  FaGlobeAmericas,
} from 'react-icons/fa';
import { LanguageContext, Lang } from '../../common';
import contactI18n from '../../content/contactI18n';
import GoogleMap from './GoogleMap';
// import SimpleMap from './SimpleMap';

export default function Contact() {
  const currentLanguage = useContext(LanguageContext).lang;
  const lang = new Lang(contactI18n, currentLanguage);

  return (
    <section id="contact" className="py-3">
      <div className="container-md">
        <h2 className="py-3 text-center">{lang.get('title')}</h2>
        <div className="row">
          <div className="mt-3 text-center">
            {lang.get('contactUsText')}
          </div>
          <div className="mb-3 text-center">
            <a href="mailto:info@agltransport.hu">
              <FaRegEnvelope className="me-2" size="2rem" />
              <span className="" style={{ fontSize: '1.1rem' }}>
                <strong>
                  info@agltransport.hu
                </strong>
              </span>
            </a>
            {' '}
            {`(${lang.get('requestForQuote')})`}
            {/* <a href="mailto:info@agltransport.hu">
            </a> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h4>{lang.get('address')}</h4>
            <div>H - 2051 Biatorbágy</div>
            <div>Rozália Park 11.</div>
            <h4 className="mt-3">{lang.get('contact')}</h4>
            <div>
              <FaPhoneAlt className="me-2" />
              +36 (23) 530-600
            </div>
            {/* <div>
              <FaFax className="me-2" />
              +36 (23) 530-619
            </div> */}
            <div>
              <FaRegEnvelope className="me-2" />
              <a href="mailto:info@agltransport.hu">
                info@agltransport.hu
              </a>
              {' '}
              {`(${lang.get('requestForQuote')})`}
            </div>
            <div>
              <FaRegEnvelope className="me-2" />
              <a href="mailto:agltrans@agltransport.hu">
                agltrans@agltransport.hu
              </a>
            </div>
            <div>
              <FaGlobeAmericas className="me-2" />
              <a href="https://www.agltransport.hu">www.agltransport.hu</a>
            </div>
          </div>
          <div className="col-md-6">
            <GoogleMap />
            {/* <SimpleMap /> */}
          </div>
        </div>
      </div>
    </section>
  );
}
