export const ABOUT = 'i18n-navbar-about';
export const SERVICES = 'i18n-navbar-services';
export const TEAM = 'i18n-navbar-team';
export const CONTACT = 'i18n-navbar-contact';
export const CAREER = 'i18n-navbar-career';
export const E_SERVICE = 'i18n-navbar-e-service';
export const E_SERVICE_SUBCONTRACTORS = 'i18n-navbar-e-service-subcontractors';
export const E_SERVICE_SUBCONTRACTORS_MY_PAGE = 'i18n-navbar-e-service-subcontractors-my-page';
export const E_SERVICE_SUBCONTRACTORS_STATUS_MANAGEMENT = 'i18n-navbar-e-service-subcontractors-status-management';
export const E_SERVICE_SHIPPER = 'i18n-navbar-e-service-shipper';
export const E_SERVICE_SHIPPER_MY_PAGE = 'i18n-navbar-e-service-shipper-my-page';
export const E_SERVICE_SHIPPER_TRACKING = 'i18n-navbar-e-service-shipper-tracking';
