// import '../../index.scss';
import 'bootstrap/dist/js/bootstrap.bundle';
import { useContext } from 'react';
import imgBanner1 from '../../assets/banner/Banner-1.jpg';
import imgBanner2 from '../../assets/banner/Banner-2.jpg';
import imgBanner3 from '../../assets/banner/Banner-3.jpg';
import { Lang, LanguageContext } from '../../common';
import { carousel1, carousel2, carousel3 } from '../../content/carouselI18n';
import './banner.scss';

export default function Banner() {
  const currentLanguage = useContext(LanguageContext).lang;
  const lang1 = new Lang(carousel1, currentLanguage);
  const lang2 = new Lang(carousel2, currentLanguage);
  const lang3 = new Lang(carousel3, currentLanguage);

  return (
    <section id="banner" className="">
      <div className="container-md">

        <div id="AGLBanner" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#AGLBanner" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" />
            <button type="button" data-bs-target="#AGLBanner" data-bs-slide-to="1" aria-label="Slide 2" />
            <button type="button" data-bs-target="#AGLBanner" data-bs-slide-to="2" aria-label="Slide 3" />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={imgBanner1} className="d-block w-100" alt="Banner 1" />
              <div className="carousel-caption carousel-position">
                <h5 className="">
                  {lang1.get('title')}
                </h5>
                <p className="d-none d-lg-block">
                  {lang1.get('text')}
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={imgBanner2} className="d-block w-100" alt="Banner 2" />
              <div className="carousel-caption carousel-position">
                <h5 className="border-bottom border-2 border-secondary">
                  {lang2.get('title')}
                </h5>
                <p className="d-none d-lg-block">
                  {lang2.get('text')}
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={imgBanner3} className="d-block w-100" alt="Banner 3" />
              <div className="carousel-caption carousel-position">
                <h5 className="border-bottom border-2 border-secondary">
                  {lang3.get('title')}
                </h5>
                <p className="d-none d-lg-block">
                  {lang3.get('text')}
                </p>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#AGLBanner" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#AGLBanner" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
  );
}
