import { createContext } from 'react';
import Flags from 'country-flag-icons/react/3x2';

// TODO: generate languageSet from languageSettings
const languageSet = ['hu', 'en', 'de'];
const languageSettings = {
  hu: {
    flag: <Flags.HU />,
  },
  en: {
    flag: <Flags.GB />,
  },
  de: {
    flag: <Flags.DE />,
  },
};

const LanguageContext = createContext('hu');

export { languageSet, languageSettings, LanguageContext };
